<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-btn
          color="primary"
          class="mr-2"
          v-on:click="CreateWorkblock"
          style="margin-top: 15px"
        >
          Create
        </v-btn>
        <v-btn
          depressed
          color="error"
          v-on:click="DeleteWorkblock"
          style="margin-right: 15px; margin-top: 15px"
        >
          Delete
        </v-btn>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        v-model="selected"
        show-select
        :items-per-page="-1"
        @click:row="EditRow"
      >
        <template v-slot:[`item.image`]="props">
          <v-img
            :src="'data:image/jpeg;base64,' + props.item.image"
            class="grey darken-4"
            max-height="30"
            max-width="50"
            contain
          ></v-img>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="createWorkblockModal" persistent width="900px">
      <v-container class="grey lighten-5">
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Create Workblock</span>
          </v-card-title>
          <v-img
            :src="'data:image/jpeg;base64,' + workblock.image"
            class="grey darken-4"
            max-height="170"
            contain
          ></v-img>
          <v-card-text>
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an image"
              prepend-icon="mdi-camera"
              label="image"
              @change="Preview_image"
            ></v-file-input>
            <v-text-field v-model="workblock.name1" color="teal" label="name1">
            </v-text-field>
            <v-text-field v-model="workblock.name2" color="teal" label="name2">
            </v-text-field>
            <v-text-field v-model="workblock.name3" color="teal" label="name3">
            </v-text-field>          
            <v-textarea
              v-model="workblock.text1"
              acolor="teal"
              label="text1"
            >
            </v-textarea>
            <v-textarea
              v-model="workblock.text2"
              acolor="teal"
              label="text2"
            >
            </v-textarea>
            <v-textarea
              v-model="workblock.text3"
              acolor="teal"
              label="text3"
            >
            </v-textarea>
            <v-textarea
              v-model="workblock.job1"
              acolor="teal"
              label="job1"
            >
            </v-textarea>
            <v-textarea
              v-model="workblock.job2"
              acolor="teal"
              label="job2"
            >
            </v-textarea>
            <v-textarea
              v-model="workblock.job3"
              acolor="teal"
              label="job3"
            >
            </v-textarea>
            <v-textarea
              v-model="workblock.email"
              acolor="teal"
              label="email"
            >
            </v-textarea>
            <v-textarea
              v-model="workblock.phone"
              acolor="teal"
              label="phone"
            >
            </v-textarea>
            <v-checkbox
              v-model="workblock.islead"
              acolor="teal"
              label="lead"
            >
            </v-checkbox>
            <v-checkbox
              v-model="workblock.iswork"
              acolor="teal"
              label="work"
            >
            </v-checkbox>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-on:click="CreateWorkblock(workblock)"
                >
                  Save
                </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close">
                  Cancel
                </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>

    <!-- Edit Workblock -->
    <v-dialog v-model="editWorkblockModal" persistent width="900px">
      <v-container class="grey lighten-5">
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Edit Workblock</span>
          </v-card-title>
          <v-img
            :src="'data:image/jpeg;base64,' + workblock.image"
            class="grey darken-4"
            max-height="170"
            contain
          ></v-img>
          <v-card-text>
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an image"
              prepend-icon="mdi-camera"
              label="image"
              @change="Preview_image"
            ></v-file-input>
            <v-text-field v-model="workblock.name1" color="teal" label="name1">
            </v-text-field>
            <v-text-field v-model="workblock.name2" color="teal" label="name2">
            </v-text-field>
            <v-text-field v-model="workblock.name3" color="teal" label="name3">
            </v-text-field>
            <v-textarea
              v-model="workblock.text1"
              acolor="teal"
              label="text1"
            >
            </v-textarea>
            <v-textarea
              v-model="workblock.text2"
              acolor="teal"
              label="text2"
            >
            </v-textarea>
            <v-textarea
              v-model="workblock.text3"
              acolor="teal"
              label="text3"
            >
            </v-textarea>
            <v-textarea
              v-model="workblock.job1"
              acolor="teal"
              label="job1"
            >
            </v-textarea>
            <v-textarea
              v-model="workblock.job2"
              acolor="teal"
              label="job2"
            >
            </v-textarea>
            <v-textarea
              v-model="workblock.job3"
              acolor="teal"
              label="job3"
            >
            </v-textarea>
            <v-textarea
              v-model="workblock.email"
              acolor="teal"
              label="email"
            >
            </v-textarea>
            <v-textarea
              v-model="workblock.phone"
              acolor="teal"
              label="phone"
            >
            </v-textarea>
            <v-checkbox
              v-model="workblock.islead"
              acolor="teal"
              label="lead"
            >
            </v-checkbox>
            <v-checkbox
              v-model="workblock.iswork"
              acolor="teal"
              label="work"
            >
            </v-checkbox>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-on:click="Update(workblock)"
                >
                  Update
                </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close">
                  Cancel
                </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
const axios = require("axios").default;
export default {
  name: "Workblocks",
  data: () => ({
    selected: [],
    search: "",
    image: "",
    createWorkblockModal: false,
    editWorkblockModal: false,
    workblock: {
      // id: null,
      image: "",
      name1: "",
      name2: "",
      name3: "",
      text1: "",
      text2: "",
      text3: "",
      job1: "",
      job2: "",
      job3: "",
      email: "",
      phone: "",
      islead: false,
      iswork: false,
    },
    headers: [
      {
        text: "Image",
        align: "start",
        sortable: false,
        value: "image",
      },
      {
        text: "Name1",
        align: "start",
        sortable: false,
        value: "name1",
      },
      {
        text: "Name2",
        align: "start",
        sortable: false,
        value: "name2",
      },
      {
        text: "Name3",
        align: "start",
        sortable: false,
        value: "name3",
      },
      {
        text: "Text1",
        align: "start",
        sortable: false,
        value: "text1",
      },
      {
        text: "Text2",
        align: "start",
        sortable: false,
        value: "text2",
      },
      {
        text: "Text3",
        align: "start",
        sortable: false,
        value: "text3",
      },
      {
        text: "job1",
        align: "start",
        sortable: false,
        value: "job1",
      },
      {
        text: "job2",
        align: "start",
        sortable: false,
        value: "job2",
      },
      {
        text: "job3",
        align: "start",
        sortable: false,
        value: "job3",
      },
      {
        text: "email",
        align: "start",
        sortable: false,
        value: "email",
      },
      {
        text: "phone",
        align: "start",
        sortable: false,
        value: "phone",
      },
      {
        text: "Leader?",
        align: "start",
        sortable: false,
        value: "islead",
      },
      {
        text: "Worker?",
        align: "start",
        sortable: false,
        value: "iswork",
      },
    ],
    items: [],
    config: "",
  }),

  created: function () {
    this.getWorkblock();
  },
//   mounted: {
//   },
  methods: {
    EditRow(e) {
      this.editWorkblockModal = true;
      this.workblock = {
        id: e.id,
        image: e.image,
        name1: e.name1,
        name2: e.name2,
        name3: e.name3,
        text1: e.text1,
        text2: e.text2,
        text3: e.text3,
        job1: e.job1,
        job2: e.job2,
        job3: e.job3,
        email: e.email,
        phone: e.phone,
        islead: e.islead,
        iswork: e.iswork,
      };
    },
    getNameValue: function (key) {
      if (key !== undefined) {
        let name = this.items.find((x) => x.name === key);
        console.log(this.items);
        if (name !== undefined) {
          console.log(name["value" + this.langKey]);
          return name["value" + this.langKey];
        }
        return key;
      }
    },
    async getWorkblock() {
      await this.$store.dispatch("getAllWorkblocks");
      this.items = await this.$store.getters.getWorkblocks;
      // let response = await axios.get(
      //   "http://95.216.78.44:5542/Samupak/GetAllWorkblock"
      // );
      // if (response.statusText === "OK") {
      //   this.items = response.data;
      //   this.editWorkblockModal = false;
      // }
    },
    async Update(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let responseData = false;
      axios
        .post(this.config + "/UpdateWorkblock", item)
        .then((response) => {
          alert(response.data ? "Update Succsess" : "Update error");
          responseData = response.data;
          this.Close();
          this.UpdateAfter(responseData, item);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    async UpdateAfter(responseData, item) {
      if (responseData) {
        let data = await this.getWorkblockById(item.id);
        let index = this.items.findIndex((x) => x.id == item.id);
        this.items.splice(index, 1);
        if (data) {
          this.items.push(data);
        }
      }
    },
    async Save(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.post(
        this.config + "/SaveWorkblock",
        item
      );
      return response.data;
    },
    async CreateWorkblock(item) {
      if (!this.createWorkblockModal) {
        this.createWorkblockModal = true;
        this.image = "";
      } else {
        let response = await this.Save(item);
        this.Close();
        alert("Update your page");
        if (response != null) {
          let data = await this.getWorkblockById(response);
          if (data) {
            this.items.push(data);
          }

          this.workblock = {
            id: null,
            name1: "",
            image: [],
            date: "",
            text1: "",            
          };
          this.Close();
        } else {
          alert("Save error");
        }
      }
    },
    async getWorkblockById(id) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.get(
        this.config + "/GetWorkblock/" + id
      );
      return response.data;
    },
    async DeleteWorkblock() {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      if (this.selected.length > 0) {
        let response = await axios.post(
          this.config + "/DeleteWorkblock",
          this.selected
        );
        for (let key in response.data) {
          if (response.data[key]) {
            let index = this.items.findIndex((x) => x.id === key);
            console.log(index);
            console.log(this.items[index]);
            this.items.splice(index, 1);
          }
        }
      }
    },
    Close() {
      this.createWorkblockModal = false;
      this.editWorkblockModal = false;
      this.workblock = {
        id: null,
        name1: "",
        image: "",
        text1: "",
      };
    },
    async Preview_image(e) {
      this.image = e;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.workblock.image = e.target.result
          .replace("data:", "")
          .replace(/^.+,/, "");
      };
      reader.readAsDataURL(this.image);
    },
  },
};
</script>
<style>
#date {
  background-color: rgb(235, 235, 235);
  width: 100%;
}
</style>