<template>
  <component :is="component" />
</template>

<script>
import Textblock from "../components/Textblock.vue";
import Pictureblock from "../components/Pictureblock.vue";
import Newsblock from "../components/Newsblock.vue";
import Articleblock from "../components/Articleblock.vue";
import Licenseblock from "../components/Licenseblock.vue"
import Partnerblock from "../components/Partnerblock.vue"
import Workblock from "../components/Workblock.vue"
export default {
  name: "dynamic",
  props: ["component"],
  components: {
    Textblock,
    Pictureblock,
    Newsblock,
    Articleblock,
    Licenseblock,
    Partnerblock,
    Workblock,
  },
};
</script>