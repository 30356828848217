<template>
  <div class="admin-panel">
    <v-tabs v-model="tab" align-with-title id ="tabs">
      <v-tabs-slider color="blue"></v-tabs-slider>
      <v-tab v-for="item in items" :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>
    <!-- <v-tabs-items v-model="tab">
      <v-tab-item>
        <keep-alive>
          <Textblock />
        </keep-alive>
      </v-tab-item>
      <v-tab-item>
        <keep-alive>
          <Pictureblock />
        </keep-alive>
      </v-tab-item>
    </v-tabs-items> -->
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item">
        <keep-alive>
          <dynamic :component="item" />
        </keep-alive>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
// @ is an alias to /src
// import Textblock from "../components/Textblock";
// import Pictureblock from "../components/Pictureblock.vue";
import Dynamic from "../components/Dynamic.vue";

export default {
  name: "AdminPanel",
  data() {
    return {
      tab: null,
      items: ["Textblock", "Pictureblock", "Newsblock", "Articleblock", "Licenseblock", "Partnerblock", "Workblock"],
    };
  },
  components: {
    Dynamic,
  },
  mounted() {
    if (localStorage.LastLogin != null) {
      let currentTime = Date.now();
      let diff = currentTime - localStorage.LastLogin;
      console.log(diff);
      if (diff > 10800000) {
        this.$router.push("/Login");
      }
    } else {
      this.$router.push("/Login");
    }
  },
};
</script>

<style>
#tabs {
  margin-top: 50px;
}
.admin-panel {
  
  margin-top: 100px;

}
@media (max-width: 750px) {
  .admin-panel {
  
  margin-top: 180px;

}
}
</style>