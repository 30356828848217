<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-btn
          color="primary"
          class="mr-2"
          v-on:click="CreateNewsblock"
          style="margin-top: 15px"
        >
          Create
        </v-btn>
        <v-btn
          depressed
          color="error"
          v-on:click="DeleteNewsblock"
          style="margin-right: 15px; margin-top: 15px"
        >
          Delete
        </v-btn>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        v-model="selected"
        show-select
        :items-per-page="-1"
        @click:row="EditRow"
      >
        <template v-slot:[`item.image`]="props">
          <v-img
            :src="'data:image/jpeg;base64,' + props.item.image"
            class="grey darken-4"
            max-height="30"
            max-width="50"
            contain
          ></v-img>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="createNewsblockModal" persistent width="900px">
      <v-container class="grey lighten-5">
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Create Newsblock</span>
          </v-card-title>
          <v-img
            :src="'data:image/jpeg;base64,' + newsblock.image"
            class="grey darken-4"
            max-height="170"
            contain
          ></v-img>
          <v-card-text>
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an image"
              prepend-icon="mdi-camera"
              label="image"
              @change="Preview_image"
            ></v-file-input>
            <v-text-field v-model="newsblock.name1" color="teal" label="name1">
            </v-text-field>
            <v-text-field v-model="newsblock.name2" color="teal" label="name2">
            </v-text-field>
            <v-text-field v-model="newsblock.name3" color="teal" label="name3">
            </v-text-field>
            <div>date</div>
            <vue-pikaday v-model="newsblock.date" color="teal" id="date" label="date"  :options="pikadayOptions" />
            <hr>
            <v-textarea
              v-model="newsblock.shorttext1"
              acolor="teal"
              label="shorttext1"
            >
            </v-textarea>
            <v-textarea
              v-model="newsblock.shorttext2"
              acolor="teal"
              label="shorttext2"
            >
            </v-textarea>
            <v-textarea
              v-model="newsblock.shorttext3"
              acolor="teal"
              label="shorttext3"
            >
            </v-textarea>
            <v-textarea
              v-model="newsblock.longtext1"
              acolor="teal"
              label="longtext1"
            >
            </v-textarea>
            <v-textarea
              v-model="newsblock.longtext2"
              acolor="teal"
              label="longtext2"
            >
            </v-textarea>
            <v-textarea
              v-model="newsblock.longtext3"
              acolor="teal"
              label="longtext3"
            >
            </v-textarea>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-on:click="CreateNewsblock(newsblock)"
                >
                  Save
                </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close">
                  Cancel
                </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>

    <!-- Edit Newsblock -->
    <v-dialog v-model="editNewsblockModal" persistent width="900px">
      <v-container class="grey lighten-5">
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Edit Newsblock</span>
          </v-card-title>
          <v-img
            :src="'data:image/jpeg;base64,' + newsblock.image"
            class="grey darken-4"
            max-height="170"
            contain
          ></v-img>
          <v-card-text>
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an image"
              prepend-icon="mdi-camera"
              label="image"
              @change="Preview_image"
            ></v-file-input>
            <v-text-field v-model="newsblock.name1" color="teal" label="name1">
            </v-text-field>
            <v-text-field v-model="newsblock.name2" color="teal" label="name2">
            </v-text-field>
            <v-text-field v-model="newsblock.name3" color="teal" label="name3">
            </v-text-field>
            <div>date</div>
            <vue-pikaday v-model="newsblock.date" color="teal" id="date" label="date"  :options="pikadayOptions" />
            <hr>
            <v-textarea
              v-model="newsblock.shorttext1"
              acolor="teal"
              label="shorttext1"
            >
            </v-textarea>
            <v-textarea
              v-model="newsblock.shorttext2"
              acolor="teal"
              label="shorttext2"
            >
            </v-textarea>
            <v-textarea
              v-model="newsblock.shorttext3"
              acolor="teal"
              label="shorttext3"
            >
            </v-textarea>
            <v-textarea
              v-model="newsblock.longtext1"
              acolor="teal"
              label="longtext1"
            >
            </v-textarea>
            <v-textarea
              v-model="newsblock.longtext2"
              acolor="teal"
              label="longtext2"
            >
            </v-textarea>
            <v-textarea
              v-model="newsblock.longtext3"
              acolor="teal"
              label="longtext3"
            >
            </v-textarea>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-on:click="Update(newsblock)"
                >
                  Update
                </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close">
                  Cancel
                </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
const axios = require("axios").default;
import '@netcz/vue-pikaday';
export default {
  name: "Newsblocks",
  data: () => ({
    selected: [],
    search: "",
    image: "",
    createNewsblockModal: false,
    editNewsblockModal: false,
    newsblock: {
      // Пока под вопросом
      // id: null,
      image: "",
      name1: "",
      name2: "",
      name3: "",
      date: "",
      shorttext1: "",
      shorttext2: "",
      shorttext3: "",
      longtext1: "",
      longtext2: "",
      longtext3: "",
    },
    
    headers: [
      {
        text: "Image",
        align: "start",
        sortable: false,
        value: "image",
      },
      {
        text: "Name1",
        align: "start",
        sortable: false,
        value: "name1",
      },
      {
        text: "Name2",
        align: "start",
        sortable: false,
        value: "name2",
      },
      {
        text: "Name3",
        align: "start",
        sortable: false,
        value: "name3",
      },
      {
        text: "Date",
        align: "start",
        sortable: false,
        value: "date",
      },
      {
        text: "ShortText1",
        align: "start",
        sortable: false,
        value: "shorttext1",
      },
      {
        text: "ShortText2",
        align: "start",
        sortable: false,
        value: "shorttext2",
      },
      {
        text: "ShortText3",
        align: "start",
        sortable: false,
        value: "shorttext3",
      },
      {
        text: "LongText1",
        align: "start",
        sortable: false,
        value: "longtext1",
      },
      {
        text: "LongText2",
        align: "start",
        sortable: false,
        value: "longtext2",
      },
      {
        text: "LongText3",
        align: "start",
        sortable: false,
        value: "longtext3",
      },
    ],
    items: [],
    config: "",
    pikadayOptions: {
          format: 'YYYY-MM-DD',
        }
  }),

  created: function () {
    this.getNewsblock();
  },
  // mounted: {},
  methods: {
    EditRow(e) {
      this.editNewsblockModal = true;
      this.newsblock = {
        id: e.id,
        image: e.image,
        name1: e.name1,
        name2: e.name2,
        name3: e.name3,
        date: e.date,
        shorttext1: e.shorttext1,
        shorttext2: e.shorttext2,
        shorttext3: e.shorttext3,
        longtext1: e.longtext1,
        longtext2: e.longtext2,
        longtext3: e.longtext3,
      };
    },
    getNameValue: function (key) {
      if (key !== undefined) {
        let name = this.items.find((x) => x.name === key);
        console.log(this.items);
        if (name !== undefined) {
          console.log(name["value" + this.langKey]);
          return name["value" + this.langKey];
        }
        return key;
      }
    },
    async getNewsblock() {
      await this.$store.dispatch("getAllNewsblocks");
      this.items = await this.$store.getters.getNewsblocks;
      // let response = await axios.get(
      //   "http://95.216.78.44:5542/Samupak/GetAllNewsblock"
      // );
      // if (response.statusText === "OK") {
      //   this.items = response.data;
      //   this.editNewsblockModal = false;
      // }
    },
    async Update(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let responseData = false;
      axios
        .post(this.config + "/UpdateNewsblock", item)
        .then((response) => {
          alert(response.data ? "Update Succsess" : "Update error");
          responseData = response.data;
          this.Close();
          this.UpdateAfter(responseData, item);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    async UpdateAfter(responseData, item) {
      if (responseData) {
        let data = await this.getNewsblockById(item.id);
        let index = this.items.findIndex((x) => x.id == item.id);
        this.items.splice(index, 1);
        if (data) {
          this.items.push(data);
        }
      }
    },
    async Save(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.post(
        this.config + "/SaveNewsblock",
        item
      );
      return response.data;
    },
    async CreateNewsblock(item) {
      if (!this.createNewsblockModal) {
        this.createNewsblockModal = true;
        this.image = "";
      } else {
        let response = await this.Save(item);
        this.Close();
        alert("Update your page");
        if (response != null) {
          let data = await this.getNewsblockById(response);
          if (data) {
            this.items.push(data);
          }

          this.newsblock = {
            id: null,
            name1: "",
            image: [],
            date: "",
            shorttext1: "",
            longtext1: "",
          };
          this.Close();
        } else {
          alert("Save error");
        }
      }
    },
    async getNewsblockById(id) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.get(
        this.config + "/GetNewsblock/" + id
      );
      return response.data;
    },
    async DeleteNewsblock() {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      if (this.selected.length > 0) {
        let response = await axios.post(
          this.config + "/DeleteNewsblock",
          this.selected
        );
        for (let key in response.data) {
          if (response.data[key]) {
            let index = this.items.findIndex((x) => x.id === key);
            console.log(index);
            console.log(this.items[index]);
            this.items.splice(index, 1);
          }
        }
      }
    },
    Close() {
      this.createNewsblockModal = false;
      this.editNewsblockModal = false;
      this.newsblock = {
        id: null,
        name1: "",
        image: "",
        date: "",
        shorttext1: "",
        longtext1: "",
      };
    },
    async Preview_image(e) {
      this.image = e;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.newsblock.image = e.target.result
          .replace("data:", "")
          .replace(/^.+,/, "");
      };
      reader.readAsDataURL(this.image);
    },
  },
};
</script>
<style>
#date {
  background-color: rgb(235, 235, 235);
  width: 100%;
}
</style>
