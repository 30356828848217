<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-btn
          color="primary"
          class="mr-2"
          v-on:click="CreateArticleblock"
          style="margin-top: 15px"
        >
          Create
        </v-btn>
        <v-btn
          depressed
          color="error"
          v-on:click="DeleteArticleblock"
          style="margin-right: 15px; margin-top: 15px"
        >
          Delete
        </v-btn>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        v-model="selected"
        show-select
        :items-per-page="-1"
        @click:row="EditRow"
      >
        <template v-slot:[`item.file`]="props">
          <v-img
            :src="'data:application/pdf;base64,' + props.item.file"
            class="grey darken-4"
            max-height="30"
            max-width="50"
            contain
          ></v-img>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="createArticleblockModal" persistent width="900px">
      <v-container class="grey lighten-5">
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Create Articleblock</span>
          </v-card-title>
          <v-img
            :src="'data:application/pdf;base64,' + articleblock.file"
            class="grey darken-4"
            max-height="170"
            contain
          ></v-img>
          <v-card-text>
            <v-file-input
              accept="application/pdf, application/docx, application/doc"
              placeholder="Pick an file"
              prepend-icon="mdi-camera"
              label="file"
              @change="Preview_image"
            ></v-file-input>
            <v-text-field v-model="articleblock.name" color="teal" label="name">
            </v-text-field>
            <v-text-field v-model="articleblock.size" color="teal" label="size">
            </v-text-field>
            <v-textarea
              v-model="articleblock.text1"
              acolor="teal"
              label="text1"
            >
            </v-textarea>
            <v-textarea
              v-model="articleblock.text2"
              acolor="teal"
              label="text2"
            >
            </v-textarea>
            <v-textarea
              v-model="articleblock.text3"
              acolor="teal"
              label="text3"
            >
            </v-textarea>
            <v-textarea
              v-model="articleblock.description1"
              acolor="teal"
              label="description1"
            >
            </v-textarea>
            <v-textarea
              v-model="articleblock.description2"
              acolor="teal"
              label="description2"
            >
            </v-textarea>
            <v-textarea
              v-model="articleblock.description3"
              acolor="teal"
              label="description3"
            >
            </v-textarea>
            <v-checkbox
              v-model="articleblock.isarticle"
              acolor="teal"
              label="article"
            >
            </v-checkbox>
            <v-checkbox
              v-model="articleblock.ispresentation"
              acolor="teal"
              label="presentation"
            >
            </v-checkbox>
            <v-checkbox
              v-model="articleblock.isclientsref"
              acolor="teal"
              label="clientsref"
            >
            </v-checkbox>
            <v-text-field v-model="articleblock.sortparam" color="teal" label="sorting parameter">
            </v-text-field>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-on:click="CreateArticleblock(articleblock)"
                >
                  Save
                </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close">
                  Cancel
                </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>

    <!-- Edit Articleblock -->
    <v-dialog v-model="editArticleblockModal" persistent width="900px">
      <v-container class="grey lighten-5">
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Edit Articleblock</span>
          </v-card-title>
          <v-img
            :src="'data:application/pdf;base64,' + articleblock.file"
            class="grey darken-4"
            max-height="170"
            contain
          ></v-img>
          <v-card-text>
            <v-file-input
              accept="application/pdf, application/docx, application/doc"
              placeholder="Pick an file"
              prepend-icon="mdi-camera"
              label="file"
              @change="Preview_image"
            ></v-file-input>
            <v-text-field v-model="articleblock.name" color="teal" label="name">
            </v-text-field>
            <v-text-field v-model="articleblock.size" color="teal" label="size">
            </v-text-field>
            <v-textarea
              v-model="articleblock.text1"
              acolor="teal"
              label="text1"
            >
            </v-textarea>
            <v-textarea
              v-model="articleblock.text2"
              acolor="teal"
              label="text2"
            >
            </v-textarea>
            <v-textarea
              v-model="articleblock.text3"
              acolor="teal"
              label="text3"
            >
            </v-textarea>
            <v-textarea
              v-model="articleblock.description1"
              acolor="teal"
              label="description1"
            >
            </v-textarea>
            <v-textarea
              v-model="articleblock.description2"
              acolor="teal"
              label="description2"
            >
            </v-textarea>
            <v-textarea
              v-model="articleblock.description3"
              acolor="teal"
              label="description3"
            >
            </v-textarea>
            <v-checkbox
              v-model="articleblock.isarticle"
              acolor="teal"
              label="article"
            >
            </v-checkbox>
            <v-checkbox
              v-model="articleblock.ispresentation"
              acolor="teal"
              label="presentation"
            >
            </v-checkbox>
            <v-checkbox
              v-model="articleblock.isclientsref"
              acolor="teal"
              label="clientsref"
            >
            </v-checkbox>
            <v-text-field v-model="articleblock.sortparam" color="teal" label="sorting parameter">
            </v-text-field>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-on:click="Update(articleblock)"
                >
                  Update
                </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close">
                  Cancel
                </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
const axios = require("axios").default;

export default {
  name: "Articleblocks",
  data: () => ({
    selected: [],
    search: "",
    file: "",
    createArticleblockModal: false,
    editArticleblockModal: false,
    articleblock: {
      //
      file: "",
      name: "",
      size: "",
      text1: "",
      text2: "",
      text3: "",
      description1: "",
      description2: "",
      description3: "",
      isarticle: false,
      ispresentation: false,
      isclientsref: false,
      sortparam: "",
    },

    headers: [
      {
        text: "File",
        align: "start",
        sortable: false,
        value: "file",
      },
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Size",
        align: "start",
        sortable: false,
        value: "size",
      },
      {
        text: "Text1",
        align: "start",
        sortable: false,
        value: "text1",
      },
      {
        text: "Text2",
        align: "start",
        sortable: false,
        value: "text2",
      },
      {
        text: "Text3",
        align: "start",
        sortable: false,
        value: "text3",
      },
      {
        text: "Description1",
        align: "start",
        sortable: false,
        value: "description1",
      },
      {
        text: "Description2",
        align: "start",
        sortable: false,
        value: "description2",
      },
      {
        text: "Description3",
        align: "start",
        sortable: false,
        value: "description3",
      },
      {
        text: "Article?",
        align: "start",
        sortable: false,
        value: "isarticle",
      },
      {
        text: "Presentation?",
        align: "start",
        sortable: false,
        value: "ispresentation",
      },
      {
        text: "Clients ref?",
        align: "start",
        sortable: false,
        value: "isclientsref",
      },
      {
        text: "Sorting Parameter",
        align: "start",
        sortable: false,
        value: "sortparam",
      }
    ],
    items: [],
    config: "",
  }),

  created: function () {
    this.getArticleblock();
  },
  // mounted: {},
  methods: {
    EditRow(e) {
      this.editArticleblockModal = true;
      this.articleblock = {
        id: e.id,
        file: e.file,
        name: e.name,
        size: e.size,
        text1: e.text1,
        text2: e.text2,
        text3: e.text3,
        description1: e.description1,
        description2: e.description2,
        description3: e.description3,
        isarticle: e.isarticle,
        ispresentation: e.ispresentation,
        isclientsref: e.isclientsref,
        sortparam: e.sortparam,
      };
    },
    getNameValue: function (key) {
      if (key !== undefined) {
        let name = this.items.find((x) => x.name === key);
        console.log(this.items);
        if (name !== undefined) {
          console.log(name["value" + this.langKey]);
          return name["value" + this.langKey];
        }
        return key;
      }
    },
    async getArticleblock() {
      await this.$store.dispatch("getAllArticleblocks");
      this.items = await this.$store.getters.getArticleblocks;
      console.log (this.items)
      // let response = await axios.get(
      //   "http://95.216.78.44:5542/Samupak/GetAllNewsblock"
      // );
      // if (response.statusText === "OK") {
      //   this.items = response.data;
      //   this.editArticleblockModal = false;
      // }
    },
    async Update(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let responseData = false;
      axios
        .post(this.config + "/UpdateArticleblock", item)
        .then((response) => {
          alert(response.data ? "Update Succsess" : "Update error");
          responseData = response.data;
          this.Close();
          this.UpdateAfter(responseData, item);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    async UpdateAfter(responseData, item) {
      if (responseData) {
        let data = await this.getArticleblockById(item.id);
        let index = this.items.findIndex((x) => x.id == item.id);
        this.items.splice(index, 1);
        if (data) {
          this.items.push(data);
        }
      }
    },
    async Save(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.post(this.config + "/SaveArticleblock", item);
      return response.data;
    },
    async CreateArticleblock(item) {
      if (!this.createArticleblockModal) {
        this.createArticleblockModal = true;
        this.file = "";
      } else {
        let response = await this.Save(item);
        this.Close();
        alert("Update your page");
        if (response != null) {
          let data = await this.getArticleblockById(response);
          if (data) {
            this.items.push(data);
          }

          this.articleblock = {
            id: null,
            name: "",
            file: [],
            size: "",
            text1: "",
            description1: "",
          };
          this.Close();
        } else {
          alert("Save error");
        }
      }
    },
    async getArticleblockById(id) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.get(this.config + "/GetArticleblock/" + id);
      return response.data;
    },
    async DeleteArticleblock() {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      if (this.selected.length > 0) {
        let response = await axios.post(
          this.config + "/DeleteArticleblock",
          this.selected
        );
        for (let key in response.data) {
          if (response.data[key]) {
            let index = this.items.findIndex((x) => x.id === key);
            console.log(index);
            console.log(this.items[index]);
            this.items.splice(index, 1);
          }
        }
      }
    },
    Close() {
      this.createArticleblockModal = false;
      this.editArticleblockModal = false;
      this.articleblock = {
        id: null,
        name: "",
        file: "",
        size: "",
        text1: "",
        description1: "",
      };
    },
    async Preview_image(e) {
      this.file = e;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.articleblock.file = e.target.result
          .replace("data:", "")
          .replace(/^.+,/, "");
      };
      reader.readAsDataURL(this.file);
    },
  },
};
</script>
<style>
#date {
  background-color: rgb(235, 235, 235);
  width: 100%;
}
</style>