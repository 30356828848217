<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-btn
          color="primary"
          class="mr-2"
          v-on:click="CreatePartnerblock"
          style="margin-top: 15px"
        >
          Create
        </v-btn>
        <v-btn
          depressed
          color="error"
          v-on:click="DeletePartnerblock"
          style="margin-right: 15px; margin-top: 15px"
        >
          Delete
        </v-btn>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        v-model="selected"
        show-select
        :items-per-page="-1"
        @click:row="EditRow"
      >
        <template v-slot:[`item.image`]="props">
          <v-img
            :src="'data:image/jpeg;base64,' + props.item.image"
            class="grey darken-4"
            max-height="30"
            max-width="50"
            contain
          ></v-img>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="createPartnerblockModal" persistent width="900px">
      <v-container class="grey lighten-5">
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Create Partnerblock</span>
          </v-card-title>
          <v-img
            :src="'data:image/jpeg;base64,' + partnerblock.image"
            class="grey darken-4"
            max-height="170"
            contain
          ></v-img>
          <v-card-text>
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an image"
              prepend-icon="mdi-camera"
              label="image"
              @change="Preview_image"
            ></v-file-input>
            <v-textarea
              v-model="partnerblock.text1"
              acolor="teal"
              label="text1"
            >
            </v-textarea>
            <v-textarea
              v-model="partnerblock.text2"
              acolor="teal"
              label="text2"
            >
            </v-textarea>
            <v-textarea
              v-model="partnerblock.text3"
              acolor="teal"
              label="text3"
            >
            </v-textarea>
            <v-textarea
              v-model="partnerblock.site"
              acolor="teal"
              label="site"
            >
            </v-textarea>
            <v-textarea
              v-model="partnerblock.phone"
              acolor="teal"
              label="phone"
            >
            </v-textarea>
            <v-checkbox
              v-model="partnerblock.ispartn"
              acolor="teal"
              label="partn"
            >
            </v-checkbox>
            <v-checkbox
              v-model="partnerblock.isclient"
              acolor="teal"
              label="client"
            >
            </v-checkbox>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-on:click="CreatePartnerblock(partnerblock)"
                >
                  Save
                </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close">
                  Cancel
                </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>

    <!-- Edit Partnerblock -->
    <v-dialog v-model="editPartnerblockModal" persistent width="900px">
      <v-container class="grey lighten-5">
        <v-card>
          <v-card-title style="justify-content: center">
            <span class="headline">Edit Partnerblock</span>
          </v-card-title>
          <v-img
            :src="'data:image/jpeg;base64,' + partnerblock.image"
            class="grey darken-4"
            max-height="170"
            contain
          ></v-img>
          <v-card-text>
            <v-file-input
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an image"
              prepend-icon="mdi-camera"
              label="image"
              @change="Preview_image"
            ></v-file-input>
           <v-textarea
              v-model="partnerblock.text1"
              acolor="teal"
              label="text1"
            >
            </v-textarea>
            <v-textarea
              v-model="partnerblock.text2"
              acolor="teal"
              label="text2"
            >
            </v-textarea>
            <v-textarea
              v-model="partnerblock.text3"
              acolor="teal"
              label="text3"
            >
            </v-textarea>
            <v-textarea
              v-model="partnerblock.site"
              acolor="teal"
              label="site"
            >
            </v-textarea>
            <v-textarea
              v-model="partnerblock.phone"
              acolor="teal"
              label="phone"
            >
            </v-textarea>
            <v-checkbox
              v-model="partnerblock.ispartn"
              acolor="teal"
              label="partn"
            >
            </v-checkbox>
            <v-checkbox
              v-model="partnerblock.isclient"
              acolor="teal"
              label="client"
            >
            </v-checkbox>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn
                  color="primary"
                  class="mr-2"
                  v-on:click="Update(partnerblock)"
                >
                  Update
                </v-btn>
                <v-btn color="primary" class="mr-2" v-on:click="Close">
                  Cancel
                </v-btn>
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
const axios = require("axios").default;
export default {
  name: "Partnerblocks",
  data: () => ({
    selected: [],
    search: "",
    image: "",
    createPartnerblockModal: false,
    editPartnerblockModal: false,
    partnerblock: {
      // id: null,
      image: "",
      text1: "",
      text2: "",
      text3: "",
      site: "",
      phone: "",
      ispartn: false,
      isclient: false,
    },

    headers: [
      {
        text: "Image",
        align: "start",
        sortable: false,
        value: "image",
      },
      {
        text: "text1",
        align: "start",
        sortable: false,
        value: "text1",
      },
      {
        text: "text2",
        align: "start",
        sortable: false,
        value: "text2",
      },
      {
        text: "text3",
        align: "start",
        sortable: false,
        value: "text3",
      },
      {
        text: "site",
        align: "start",
        sortable: false,
        value: "site",
      },
      {
        text: "phone",
        align: "start",
        sortable: false,
        value: "phone",
      },
      {
        text: "Partner?",
        align: "start",
        sortable: false,
        value: "ispartn",
      },
      {
        text: "Client?",
        align: "start",
        sortable: false,
        value: "isclient",
      },
    ],
    items: [],
    config: "",
  }),

  created: function () {
    this.getPartnerblock();
  },
  // mounted: {},
  methods: {
    EditRow(e) {
      this.editPartnerblockModal = true;
      this.partnerblock = {
        id: e.id,
        image: e.image,
        text1: e.text1,
        text2: e.text2,
        text3: e.text3,
        site: e.site,
        phone: e.phone,
        ispartn: e.ispartn,
        isclient: e.isclient,
      };
    },
    getNameValue: function (key) {
      if (key !== undefined) {
        let name = this.items.find((x) => x.name === key);
        console.log(this.items);
        if (name !== undefined) {
          console.log(name["value" + this.langKey]);
          return name["value" + this.langKey];
        }
        return key;
      }
    },
    async getPartnerblock() {
      await this.$store.dispatch("getAllPartnerblocks");
      this.items = await this.$store.getters.getPartnerblocks;
      // let response = await axios.get(
      //   "http://95.216.78.44:5542/Samupak/GetAllPartnerblock"
      // );
      // if (response.statusText === "OK") {
      //   this.items = response.data;
      //   this.editPartnerblockModal = false;
      // }
    },
    async Update(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let responseData = false;
      axios
        .post(this.config + "/UpdatePartnerblock", item)
        .then((response) => {
          alert(response.data ? "Update Succsess" : "Update error");
          responseData = response.data;
          this.Close();
          this.UpdateAfter(responseData, item);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
    async UpdateAfter(responseData, item) {
      if (responseData) {
        let data = await this.getPartnerblockById(item.id);
        let index = this.items.findIndex((x) => x.id == item.id);
        this.items.splice(index, 1);
        if (data) {
          this.items.push(data);
        }
      }
    },
    async Save(item) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.post(this.config + "/SavePartnerblock", item);
      return response.data;
    },
    async CreatePartnerblock(item) {
      if (!this.createPartnerblockModal) {
        this.createPartnerblockModal = true;
        this.image = "";
      } else {
        let response = await this.Save(item);
        this.Close();
        alert("Update your page");
        if (response != null) {
          let data = await this.getPartnerblockById(response);
          if (data) {
            this.items.push(data);
          }

          this.partnerblock = {
            id: null,
            name1: "",
            image: [],
            date: "",
            text1: "",
            longtext1: "",
          };
          this.Close();
        } else {
          alert("Save error");
        }
      }
    },
    async getPartnerblockById(id) {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      let response = await axios.get(this.config + "/GetPartnerblock/" + id);
      return response.data;
    },
    async DeletePartnerblock() {
      await this.$store.dispatch("getConfig");
      this.config = await this.$store.getters.getConfig;
      if (this.selected.length > 0) {
        let response = await axios.post(
          this.config + "/DeletePartnerblock",
          this.selected
        );
        for (let key in response.data) {
          if (response.data[key]) {
            let index = this.items.findIndex((x) => x.id === key);
            console.log(index);
            console.log(this.items[index]);
            this.items.splice(index, 1);
          }
        }
      }
    },
    Close() {
      this.createPartnerblockModal = false;
      this.editPartnerblockModal = false;
      this.partnerblock = {
        id: null,
        image: "",
        text1: "",
        text2: "",
        text3: "",
        site: "",
        phone: "",
        ispartn: false,
        isclient: false,
      };
    },
    async Preview_image(e) {
      this.image = e;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.partnerblock.image = e.target.result
          .replace("data:", "")
          .replace(/^.+,/, "");
      };
      reader.readAsDataURL(this.image);
    },
  },
};
</script>
<style>
#date {
  background-color: rgb(235, 235, 235);
  width: 100%;
}
</style>
